/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent, ResponsiveGrid } from '@adobe/aem-react-editable-components';
import {
  AEMReactCompMap,
  canUseDOM,
  VanilaCardCarouselContainer as CardCarouselContainerMolecule,
  CardVertical,
} from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { FC, useState, Suspense, useEffect, useRef, useMemo, useCallback } from 'react';
import { Button, Text, size, tags, useCheckBreakpoint } from '@marriott/mi-ui-library';
import { CardCarouselProps, CarouselContainerProps } from './CobrandCarousel.types';
import { StyledCobrandCarousel } from './CobrandCarousel.styles';
import { setObserver } from '../../modules/utils';
import { useStore } from '../../modules/store/cobrandStore';
import { COBRANDSESSIONDATA } from '../../modules/constants';
import { CardOverview } from '../CardOverview';
export const CobrandCarouselConfig = {
  emptyLabel: 'CobrandCarousel',
  isEmpty: () => true,
  resourceType: `mi-aem-common-spa/components/content/cardcarouselcontainer`,
};

export const CobrandCarouselComp: FC<CardCarouselProps> = (props: CardCarouselProps) => {
  const {
    headerText,
    subHeaderText,
    ctaLabel,
    ctaLink,
    eyebrow,
    ctaType,
    cardCount,
    openInaNewTab,
    totalNumberOfCards,
    styleclass,
    cqItems,
    componentId,
    pagePath,
    itemPath,
    isAuthorMode,
    variation,
    enableTabletBreakpoint = true,
    enableCardComparisonFeature,
    openInNewTab,
    linkText,
    linkUrl,
    trackingProperties,
    ctadescMobile,
    ctadescDesktop,
    isRtl,
  } = props;
  const [enableCardComparison, setEnableCardComparison] = useState<boolean>(false);
  const [compareButtonText, setcompareButtonText] = useState<string>('');
  let { noOfCards, noOfCardsTablet } = props;
  const { cobrandData, setCobrandData } = useStore(store => store);
  const cobrandCarouselRef = useRef<HTMLDivElement>(null);
  const isViewportL = useCheckBreakpoint('viewportL');
  const isCombo = (): boolean => {
    return variation === 'combo';
  };
  const linkType = openInNewTab ? 'external' : 'internal';
  const clickTrackValue = `${trackingProperties?.trackingContentPosition}|${trackingProperties?.trackingDescription}|${linkType}|${trackingProperties?.additionalTrackingVariables}`;

  if (isCombo()) {
    noOfCards = 2;
    noOfCardsTablet = 2;
  }

  const mapper = AEMReactCompMap(props?.allowedComponents);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const cobrandDataFromSessionStorage = sessionStorage?.getItem(COBRANDSESSIONDATA);
      if (cobrandDataFromSessionStorage) {
        setCobrandData(JSON.parse(cobrandDataFromSessionStorage));
        sessionStorage.removeItem(COBRANDSESSIONDATA);
      }
    }
  }, [setCobrandData]);

  const getCardCompareUrl = useCallback(() => {
    let existingParams = '';
    let newParams = '';
    if (canUseDOM) {
      existingParams = window.location.search;
    }
    cobrandData.selectedCards
      .filter(card => card.checked)
      .forEach((element, i) => {
        newParams = newParams + '&cardtype' + (i + 1) + '=' + element.cardname;
      });
    return existingParams.length ? existingParams + newParams : existingParams + newParams.replace('&', '?');
  }, [cobrandData.selectedCards]);

  useEffect(() => {
    setObserver(componentId, cobrandCarouselRef);
    getCardCompareUrl();
    const selectedCheckboxes = cobrandData?.selectedCards?.filter(card => card?.checked === true);
    setEnableCardComparison(selectedCheckboxes.length >= 2 ? true : false);
    const checkedCheckboxes = cobrandData.selectedCards.filter(({ checked }) => checked === true);
    setcompareButtonText(checkedCheckboxes.length > 0 ? ` (${checkedCheckboxes.length})` : '');
  }, [cobrandData, componentId, getCardCompareUrl]);

  const setSelections = () => {
    if (canUseDOM) {
      sessionStorage?.setItem(COBRANDSESSIONDATA, JSON.stringify(cobrandData));
    }
  };

  // This function is used to render the component in authoring mode authorCardWrapper
  const authorCardWrapper = (index: number, innerCompStyleClass: string | undefined) => {
    return (
      <ResponsiveGrid
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pagePath={pagePath}
        itemPath={`${itemPath}/${totalNumberOfCards[index]}`}
        columnCount="12"
        gridClassNames={''}
        customClassName={clsx(innerCompStyleClass)}
        config={{
          isEmpty: () => true,
          resourceType: 'mi-aem-common-spa/components/container',
        }}
      />
    );
  };

  // This function is used to render the component in end-user mode

  const PublishCardsWrapper = useMemo(() => {
    const setSelectedCards = () => {
      //create unique array
      const ids = cobrandData.selectedCards.map(({ cardname }) => cardname);
      cobrandData.selectedCards = cobrandData.selectedCards.filter(
        ({ cardname }, index) => !ids.includes(cardname, index + 1)
      );
      const checkedCheckboxes = cobrandData.selectedCards.filter(({ checked }) => checked === true);
      setcompareButtonText(checkedCheckboxes.length > 0 ? ` (${checkedCheckboxes.length})` : '');
    };
    const publishCardWrapper = (cardName: string, jsonData: any) => {
      // eslint-disable-next-line no-prototype-builtins
      if (jsonData?.hasOwnProperty(cardName)) {
        const card = jsonData[cardName];
        const cardItems = card[':items'];
        for (const itemKey in cardItems) {
          if (Object.prototype.hasOwnProperty.call(cardItems, itemKey)) {
            let item = cardItems[itemKey];
            if (itemKey.includes('cardvertical')) {
              return (
                <li key={itemKey}>
                  <CardVertical {...item} cardVerticalVariations="standard" />;
                </li>
              );
            } else if (itemKey.includes('cardoverview') && noOfCards) {
              item['customClass'] =
                noOfCards === 2 ? 'card-overview-two-cards' : noOfCards === 3 ? 'card-overview-three-cards' : '';
            }
            const itemType = item[':type']?.split('/').pop();
            if (Object.prototype.hasOwnProperty.call(mapper, itemType)) {
              const innerComp = mapper[itemType];
              if (innerComp === 'CardOverview') {
                item = {
                  ...item,
                  enableCardComparison: enableCardComparisonFeature,
                  setSelectedCards: setSelectedCards,
                };
              }
              return (
                <li className="container px-1 d-flex" key={itemKey}>
                  <Suspense fallback={<div></div>}>
                    <CardOverview {...item} />
                  </Suspense>
                </li>
              );
            }
            return null;
          }
        }
      }
      return null;
    };
    return totalNumberOfCards?.map((cardName: string) => publishCardWrapper(cardName, cqItems));
  }, [totalNumberOfCards, cqItems, enableCardComparisonFeature, mapper, noOfCards, cobrandData]);

  return (
    <StyledCobrandCarousel
      data-testid="cardcarouselcontainer"
      data-component-name="o-cobrand-cardcarouselcontainer"
      ref={cobrandCarouselRef}
    >
      <CardCarouselContainerMolecule
        componentId={componentId}
        subHeaderText={subHeaderText}
        styleclass={styleclass}
        variation={variation}
        eyebrow={eyebrow}
        cardCount={cardCount}
        trackingProperties={trackingProperties}
        ctaLabel={ctaLabel}
        headerText={headerText}
        ctaLink={ctaLink}
        openInaNewTab={openInaNewTab}
        ctaType={ctaType}
        isCombo={isCombo}
        noOfCards={noOfCards}
        noOfCardsTablet={noOfCardsTablet}
        enableTabletBreakpoint={enableTabletBreakpoint}
        isDirectionRightToLeft={isRtl}
      >
        {isAuthorMode && Array.from({ length: totalNumberOfCards?.length }, (_, i) => authorCardWrapper(i, styleclass))}
        {!isAuthorMode && PublishCardsWrapper}
      </CardCarouselContainerMolecule>

      {enableCardComparisonFeature && (
        <div>
          <div className="col-12 d-flex justify-content-center my-3">
            <div className="text-center">
              <Text
                fontSize={size.medium}
                element={tags.paragraph}
                copyText={isViewportL ? ctadescDesktop : ctadescMobile}
                customClass={'t-subtitle-m  mb-3'}
              ></Text>
              <Button
                className={clsx('m-button-m m-button-secondary mx-auto', enableCardComparison ? '' : 'disabled')}
                isLink={true}
                href={linkUrl + getCardCompareUrl()}
                buttonCopy={linkText + compareButtonText}
                target={openInNewTab === 'true' ? '_blank' : '_self'}
                linkType={linkType}
                isDisabled={!enableCardComparison}
                trackingProperties={trackingProperties}
                custom_click_track_value={clickTrackValue}
                callback={setSelections}
              />
            </div>
          </div>
        </div>
      )}
    </StyledCobrandCarousel>
  );
};

export const CarouselEditableComponent = (props: any) => {
  return (
    <EditableComponent config={CobrandCarouselConfig} {...props}>
      <CobrandCarouselComp {...props} componentName={props?.model.cqType.split('/').pop()} />
    </EditableComponent>
  );
};

export const CobrandCarousel = (props: CarouselContainerProps) => {
  const { model } = props;
  if (model.variation === 'combo' && !model.styleclass?.includes('fullbleed')) {
    model.styleclass += ' fullbleed';
  }
  return (
    <div
      className={clsx(
        [model.styleclass?.includes('inverse') ? 'inverse' : ''],
        [model.styleclass?.includes('fullbleed') ? 'm-container-fullbleed' : ''],
        [model.styleclass?.includes('alternate') ? 'alternate' : '']
      )}
      data-testid="card-carousel"
    >
      <div className={clsx('container', { 'p-0': !model.styleclass?.includes('fullbleed') })}>
        <CarouselEditableComponent {...props} />
      </div>
    </div>
  );
};
